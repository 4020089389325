<template>
  <div class="fundologin">
    <div class="bodyformulario">
      <div class="logowrapper">
        <div class="logodiv" style="background-colo: red">
          <v-img src="imgs/logo/logocd.png" aspect-ratio="2.2"></v-img>
        </div>
      </div>


      <!-- Login Email -->
      <div v-show="loginViaEmail">
        <div>
          <div style="color:white;font-weight:bold;">{{ tipoLogin === 'cpf' ? 'CPF Ou Documento' : 'Email' }}</div>
          <v-text-field
              v-model="nome_usuario"
              data-cy="nome_usuario"
              dense
              outlined
              autocomplete="off"
              clearable
              class="inputtext"
              background-color="white"
          ></v-text-field>
        </div>
        <div>
          <!-- <div style="color:white;font-weight:bold;">{{ehospede?'N° do Apartamento':'Senha'}}</div> -->
          <div style="color:white;font-weight:bold;">N° do Apartamento</div>
          <v-text-field
              v-model="senha"
              data-cy="senha"
              dense
              autocomplete="off"
              :type="exibirSenha ? 'text' : 'password'"
              outlined
              clearable
              class="inputtext"
              background-color="white"
              v-on:keyup.enter="logar()"
              append-icon="mdi-eye"
              @click:append="exibirSenha = !exibirSenha"
          ></v-text-field>
        </div>
        <v-btn
            large
            block
            color="info"
            class="white--text font-weight-bold"
            data-cy="btn-logar"
            v-on:click="logar()"
        >
          Entrar
          <v-icon right dark>mdi-login</v-icon>
        </v-btn>

        <v-row justify="space-between" class="mx-0 my-5">
          <div class="mt-6 mb-4">
            <a href="/checkin/temp" data-cy="btn-naosouhospede" class="white--text btn-light">
              Não sou hóspede
            </a>
          </div>
          <div class="mt-6 mb-4" @click="vizualizarCardapio">
            <a data-cy="btn-naosouhospede" class="white--text btn-light">
              Vizualizar Cardapios
            </a>
          </div>
          <div class="mt-6 mb-4">
            <a href="/passrecovery" class="white--text">
              Esqueci minha senha
            </a>
          </div>
          <a href="/admin" class="white--text mt-6" style="width: 100% !important;">
            <v-btn color="warning"  style="width: 100% !important;" href="/admin" class="white--text">
              Entrar como funcionário
            </v-btn>
          </a>
        </v-row>
        <v-row justify="center" class="mt-2">
          <div>
            <v-img src="imgs/logoes.png" width="130" height="33"> </v-img>
          </div>
        </v-row>
      </div>

      <!-- Login CPF  -->
      <div v-show="!loginViaEmail">
        <div>
          <div style="color:white;font-weight:bold;">CPF ou Documento</div>
          <v-text-field
              v-model="nome_usuario"
              data-cy="nome_usuario"
              dense
              outlined
              autocomplete="off"
              clearable
              class="inputtext"
              background-color="white"
          ></v-text-field>
        </div>

        <div v-show="precisaDigitarSenha">
          <!-- <div style="color:white;font-weight:bold;">{{ehospede?'N° do Apartamento':'Senha'}}</div> -->
          <div style="color:white;font-weight:bold;">N° do Apartamento</div>
          <v-text-field
              v-model="senha"
              data-cy="senha"
              dense
              autocomplete="off"
              :type="exibirSenha ? 'text' : 'password'"
              outlined
              clearable
              class="inputtext"
              background-color="white"
              v-on:keyup.enter="logar()"
              append-icon="mdi-eye"
              @click:append="exibirSenha = !exibirSenha"
          ></v-text-field>
        </div>

        <div v-show="!precisaDigitarSenha">
          <div style="color:white;font-weight:bold;">N° do Apartamento ou Senha</div>
          <v-text-field
              v-model="senha"
              data-cy="senha"
              dense
              autocomplete="off"
              type="text"
              outlined
              clearable
              class="inputtext"
              background-color="white"
              color="gray"
              :append-icon="buscandoApartamento ? 'mdi-loading mdi-spin' : ''"
              disabled
          ></v-text-field>
        </div>

        <v-btn
            large
            block
            color="info"
            class="white--text font-weight-bold"
            data-cy="btn-logar"
            v-on:click="logar()"
        >
          Entrar
          <v-icon right dark>mdi-login</v-icon>
        </v-btn>

        <v-row justify="space-between" class="mx-0 my-5">
          <div class="mt-6 mb-4">
            <a href="/checkin/temp" data-cy="btn-naosouhospede" class="white--text btn-light">
              Não sou hóspede
            </a>
          </div>
          <div class="mt-6 mb-4" @click="vizualizarCardapio">
            <a data-cy="btn-naosouhospede" class="white--text btn-light">
              Vizualizar Cardapio
            </a>
          </div>
          <a href="/admin" class="white--text mt-6" style="width: 100% !important;">
            <v-btn color="warning"  style="width: 100% !important;" href="/admin" class="white--text">
              Entrar como funcionário
            </v-btn>
          </a>
          <div class="mt-6 mb-4">
            <a href="/passrecovery" class="white--text">
              Esqueci minha senha
            </a>
          </div>
        </v-row>
        <v-row justify="center" class="mt-2">
          <div>
            <v-img src="imgs/logoes.png" width="130" height="33"> </v-img>
          </div>
        </v-row>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Home",
  data() {
    return {
      nome_usuario: '',
      senha: null,
      tipoLogin: '',
      exibirSenha: false,

      loginViaEmail: false,
      precisaDigitarSenha: true,
      desativarInputSenha: false,
      buscandoApartamento: false,
      debounceSearch: null,
      debounceTimer: null,


      buscaAutomaticaQuarto: false,
      acessohospededireto:false,
      hospedepedidobloqueio:false,
    };
  },

  components: {},
  computed: {
    // ehospede() {
    //   return this.nome_usuario.indexOf("@") > 0;
    // }
  },
  watch: {
    nome_usuario: {
      handler: 'debouncedHandleNomeUsuarioChange',
    },
  },
  methods: {
    getPerfil(lista,id){

      let found = lista.find(x => x  == id);
      return found

    },
    loginCPF() {
      localStorage.setItem("pessoaLogada", "true");

      const url = this.$dados.BUILD_API_URL + "/hospedes/loginCPF";

      const data = {
        cpf: this.nome_usuario.trim(),
        senha: this.senha.trim(),
      };

      this.$http
          .post(url, data).then((res) => {

          localStorage.setItem("usuario", this.nome_usuario);
          localStorage.setItem("token", res.data.token);
          localStorage.setItem("nome", res.data.nome);
          localStorage.setItem("email", res.data.email);
          localStorage.setItem("nomehotel", res.data.nomehotel);
          localStorage.setItem("idhotel", res.data.idhotel);
          localStorage.setItem("numreserva", res.data.numreserva);
          localStorage.setItem("coduh", res.data.coduh);
          localStorage.setItem("datacheckin", res.data.datacheckin);
          localStorage.setItem("datacheckoutprev", res.data.datacheckoutprev);
          localStorage.setItem("idreservasfront", res.data.idreservasfront);
          localStorage.setItem("idhospede", res.data.idhospede);
          localStorage.setItem("idcheckin", res.data.idcheckin);
          localStorage.setItem("cartaoconsumo", res.data.cartaoconsumo);
          localStorage.setItem("tipo_usuario", "HOSPEDE");
          localStorage.setItem("passante", res.data.passante);
          localStorage.setItem("login_mesa", "false");

        this.$socket.io.opts.query.usuario = this.nome_usuario.trim();
        this.$socket.io.opts.query.tipo_usuario = "HOSPEDE";
        this.$socket.io.opts.query.idhotel = res.data.idhotel;

        this.$store.dispatch("set_titulo", "Cardápio");
        this.$http.defaults.headers.common["x-access-token"] =
            res.data.token;
        this.$router.push("/Hospede/Bemvindo");
        localStorage.setItem("pessoaLogada", "true");
      })
          .catch(erro => {
            if(erro.message.includes('400')){
              this.$alertar(
                  "warning",
                  'Tente Novamente',
                  "=("
              );
              return
            }
            if(erro.message.includes('401')){
              this.$alertar(
                  "warning",
                  'Login ou senha não encontrados',
                  "=("
              );
              return
            }
            if(erro.message.includes('405')){
              this.$alertar(
                  "warning",
                  'Verifique seu email',
                  "=("
              );
              return
            }
          })
    },
    async vizualizarCardapio() {
      localStorage.setItem("pessoaLogada", "false");

      const response = await this.$http
          .post(this.$dados.BUILD_API_URL + "/hospedes/acessarCardapioSemLogin")
          .then(async res => res.data)
          .catch(() => {
            this.$alertar(
                "warning",
                "Erro ao acessar cardápio",
                "=("
            );
          })

      const token = response.token
      const nomeHotel = response.nomehotel

      localStorage.setItem("token", token);
      localStorage.setItem("nomehotel", nomeHotel);
      this.$http.defaults.headers.common["x-access-token"]  = token

      setTimeout(() => this.$router.push("/Hospede/Menu"),1000)
    },
    logarHospede() {

      const data = {
        email: this.nome_usuario.trim(),
        senha: this.senha.trim(),
      }

      const url = this.$dados.BUILD_API_URL + "/hospedes/login"


      this.$http
          .post(url, data)
          .then((resp) => {
            console.log(" LOGINNNN ");
            // console.log(resp);

          localStorage.setItem("usuario", this.nome_usuario);
          localStorage.setItem("token", resp.data.token);
          localStorage.setItem("nome", resp.data.nome);
          localStorage.setItem("email", resp.data.email);
          localStorage.setItem("nomehotel", resp.data.nomehotel);
          localStorage.setItem("idhotel", resp.data.idhotel);
          localStorage.setItem("numreserva", resp.data.numreserva);
          localStorage.setItem("coduh", resp.data.coduh);
          localStorage.setItem("datacheckin", resp.data.datacheckin);
          localStorage.setItem("datacheckoutprev", resp.data.datacheckoutprev);
          localStorage.setItem("idreservasfront", resp.data.idreservasfront);
          localStorage.setItem("idhospede", resp.data.idhospede);
          localStorage.setItem("idcheckin", resp.data.idcheckin);
          localStorage.setItem("cartaoconsumo", resp.data.cartaoconsumo);
          localStorage.setItem("tipo_usuario", "HOSPEDE");
          localStorage.setItem("passante", resp.data.passante);
          localStorage.setItem("login_mesa", "false");

            this.$socket.io.opts.query.usuario = this.nome_usuario.trim();
            this.$socket.io.opts.query.tipo_usuario = "HOSPEDE";
            this.$socket.io.opts.query.idhotel = resp.data.idhotel;

            this.$store.dispatch("set_titulo", "Cardápio");
            this.$http.defaults.headers.common["x-access-token"] =
                resp.data.token;
            this.$router.push("/Hospede/Bemvindo");
            localStorage.setItem("pessoaLogada", "true");
          })
          .catch(erro => {
            if(erro.message.includes('400')){
              this.$alertar(
                  "warning",
                  'Tente Novamente',
                  "=("
              );
              return
            }
            if(erro.message.includes('401')){
              this.$alertar(
                  "warning",
                  'Login ou senha não encontrados',
                  "=("
              );
              return
            }
            if(erro.message.includes('405')){
              this.$alertar(
                  "warning",
                  'Verifique seu email',
                  "=("
              );
              return
            }

          })
    },
    logar() {
      if (!this.nome_usuario || !this.senha) {
        this.$alertar(
            "warning",
            "Login e Senha são campos Obrigatórios!",
            "=("
        );
      }

      console.log(this.tipoLogin, 'tipoLogin')

      if(this.tipoLogin == 'cpf') this.loginCPF()

      if(this.tipoLogin == 'email') this.logarHospede()

    },
    debounce(func, delay) {
      clearTimeout(this.debounceTimer);
      this.debounceTimer = setTimeout(func, delay);
    },
    async debouncedHandleNomeUsuarioChange() {
      await this.debounce(async () => {
        if(this.buscaAutomaticaQuarto) {
            this.precisaDigitarSenha = false;
            await this.buscarApartamentoPeloCPF();
        } else {
          this.precisaDigitarSenha = true;
        }
      }, 2500);
    },
    async buscarApartamentoPeloCPF() {
      if(!this.nome_usuario) return

      this.buscandoApartamento = true;

      const url = `${this.$dados.BUILD_API_URL}/hospedes/buscarApartamentoPeloCPF/${this.nome_usuario.trim()}`
      await this.$http.get(url)
          .then(res => {
            this.senha = res.data.senha;
            this.buscandoApartamento = false;
          }).catch((err) => {
            this.nome_usuario = "";
            this.senha  = "";

            this.$alertar(
                "warning",
                err.response.data.message || "Erro ao buscar apartamento",
                "=("
            );
            this.buscandoApartamento = false;
          })
    },
    async verificarIntegracaoNovoCardapioHospede() {
      this.$http(this.$dados.BUILD_API_URL +'/pdv/buscarIntegracaoNovoCardapioHospedes').then((res) => {
        if(!res.data) {
          return
        }

        if(res.data.ativo) {
          window.location.replace(`https://cardapiodigital.cd.economysoftware.com.br/${res.data.nome_cliente}`)
        }
      }).catch((error) => {
        console.log(error);
      });
    },
  },
  async beforeMount(){

        await this.$http.post(this.$dados.BUILD_API_URL + '/pdv/buscarparametrosPublicos',{chave:'ACESSO_HOSPEDE_DIRETO'})
                .then(resp =>{
                  this.acessohospededireto = resp.data[0].valor === 'true' ? true : false
                })
                .catch((ee) => {
                  console.log(ee)

                  this.acessohospededireto = false
                })

        

        await this.$http.post(this.$dados.BUILD_API_URL + '/pdv/buscarparametrosPublicos',{chave:'HOSPEDE_PEDIDO_BLOQUEIO'})
            .then(resp =>{
              this.hospedepedidobloqueio = resp.data[0].valor === 'true' ? true : false
              localStorage.setItem('hospedepedidobloqueio',this.hospedepedidobloqueio)
            })
            .catch((ee) => {

              console.log(ee)

              this.hospedepedidobloqueio = false
            })

            console.log('this.acessohospededireto', this.acessohospededireto)

        if(this.acessohospededireto){
          this.vizualizarCardapio();
        }
  },
  async mounted(){

    await this.verificarIntegracaoNovoCardapioHospede()

    this.$http(this.$dados.BUILD_API_URL +'/hospedes/verificarTipoLoginHospede').then((res) => {
      this.tipoLogin = res.data;

      this.loginViaEmail = res.data === "email";

      localStorage.setItem("tipoLogin", res.data);
    }).catch((error) => {
      console.log(error);
    });

    this.$http.post(this.$dados.BUILD_API_URL + '/pdv/buscarparametrosPublicos', {chave:'EXIBIR_TELA_BAIXAS'})
        .then(resp =>{
          if(resp.data && resp.data.length > 0){
            localStorage.setItem('exibir_tela_baixas', resp.data[0].valor)
          }
        }).catch(() =>{
      localStorage.setItem('exibir_tela_baixas', 'false')
    })

    await this.$http.post(this.$dados.BUILD_API_URL + '/pdv/buscarparametrosPublicos',{chave:'BUSCA_AUTOMATICA_QUARTO'})
        .then(resp =>{
          this.buscaAutomaticaQuarto = resp.data[0].valor === 'true' ? true : false
        })
        .catch((ee) => {

          console.log(ee)

          this.buscaAutomaticaQuarto = false
        })

    

    
  },
};
</script>

<style scoped>

.btn-light{
  border-radius: 20px;
  background: white;
  color: #1976d2 !important;
  box-shadow:  20px 20px 60px #1564b3,
  -20px -20px 60px #1d88f2;
  text-decoration: none;
  padding: .7em 1em;
}

.fundologin {
  background-color: #1976d2;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.bodyformulario {
  /* background-color: white; */
  width: 80vw;
  max-width: 350px;
}

.logowrapper{
  display: grid;
  place-content: center;
}
.logodiv {
  /* background-color:red; */
  min-height: 20vh;
  max-height: 20vh;
  width: 220px;
  display: flex;
  align-items: center;
  padding: 0px;
  justify-content: center;
}
</style>
